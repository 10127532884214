<template>
	<div class="settings">
		<h1>Settings</h1>
	</div>
</template>

<script>
export default {
	data() {},
}
</script>
